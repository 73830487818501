<template>
  <div class="full-width services">
    <div class="sections">
      <section class="section main-top-padding hidden">
        <div class="service-data">
          <ContentBox
            :title="sectionTitle"
            :list="section.description"
            :time="100"
            @setTitleHeight="setTitleHeight"
          />
        </div>
      </section>
      <section 
        class="section hidden" 
        :style="{ 'padding-top': DESKTOP ? `calc(30vh + ${contentTopPadding}px)` : '0' }"
      >
        <div 
          v-if="section.items" 
          class="service-items"
        >
          <List 
            :title="section.items.title"
            :list="section.items.list"
            :time="DESKTOP ? 1300 : 700"
          />
        </div>
        <div 
          v-else-if="section.exposition" 
          class="service-items"
        >
          <Exposition                        
            :title="section.exposition.title"
            :list="section.exposition.list" 
            :time="DESKTOP ? 1300 : 700"
          />
        </div>
      </section>
      <section class="section" v-if="DESKTOP">
        <div class="service-video">
          <VideoBox :name="videoName" :time="1500"/>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { Exposition, List, ContentBox, VideoBox } from '@/components';

export default {
  name: 'Services',
  components: {
    Exposition,
    List,
    ContentBox,
    VideoBox
  },
  data() {
    return {
      contentTopPadding: 0
    };
  },
  computed: {
    section() {
      return this.$store.getters['SERVICE'];
    },
    serviceId() {
      return this.$store.getters['CURRENT_COMPONENT'].id;
    },
    sectionTitle() {
      if (window.innerWidth > 1500) return this.section.title;

      this.section.title = this.section.title.trim();
      const titleArr = this.section.title.split(' ');
      if (titleArr.length < 3) {
        return this.section.title;
      } else {
        const [first, ...rest] = titleArr;
        return `${first} <br /> ${rest.join(' ')}`;
      }
    },
    videoName() {
      if ([0, 4, 8, 12].includes(this.serviceId)) {
        return this.MOVIE.MESSAGE;
      } else if ([1, 5, 9, 13].includes(this.serviceId)) {
        return this.MOVIE.CUBES;
      } else if ([2, 6, 10, 14].includes(this.serviceId)) {
        return this.MOVIE.TEXT;
      } else if ([3, 7, 11, 15].includes(this.serviceId)) {
        return this.MOVIE.CLEF;
      };

      return this.MOVIE.TEXT;
    }
  },
  methods: {
    setTitleHeight(height) {
      this.contentTopPadding = height;
    }
  }
}
</script>
