import { SCREEN_SIZES, MOVIE } from '@/config/constants';

export default {
  computed: {
    SCREEN_SIZE() {
      return this.$store.getters['SCREEN_SIZE'];
    },
    DESKTOP() {
      return this.SCREEN_SIZE == SCREEN_SIZES.DESKTOP;
    },
    TABLET() {
      return this.SCREEN_SIZE == SCREEN_SIZES.TABLET;
    },
    MOBILE() {
      return this.SCREEN_SIZE == SCREEN_SIZES.MOBILE;
    },
    MOVIE() {
      return MOVIE;
    }
  },
  methods: {
    execute(fn, time) {
      setTimeout(() => { fn() }, time);
    },
    animate(el, styles, speed = 0) {
      if (typeof styles !== 'object') {
        console.error('"Styles" is not an object!');
        styles = {};
      };

      let result = '';
  
      for (let props in styles) {
        result = result + `${props}: ${styles[props]};`
      };

      result = result + `transition: ${speed}s`;
      el.setAttribute('style', result);
    },
    getCoords(element) {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.documentElement.scrollTop;
      let scrollLeft = window.pageXOffset || document.documentElement.scrollLeft || document.documentElement.scrollLeft;
  
      let clientTop = document.documentElement.clientTop || document.documentElement.clientTop || 0;
      let clientLeft = document.documentElement.clientLeft || document.documentElement.clientLeft || 0;
      
      let box = element.getBoundingClientRect();

      return {
        top: box.top + scrollTop - clientTop,
        left: box.left + scrollLeft - clientLeft
      };
    },
    pushItems(from, to, speed) {
      from.forEach((item, index) => {
        setTimeout(() => { to.push(item) }, speed * index);
      });
    },
    getWidth(element) {
      return Math.round(+getComputedStyle(element).width.slice(0, -2));
    }
  }
}