<template>
  <div class="side-menu">
    <nav class="navigation">
      <ul>
        <li 
          v-for="(item, index) in menu" 
          :key="`item-${index}`" 
          class="nav-item"
        >
          <button 
            :class="[
              'parent-nav-button f-medium-grey uppercase pointer mb-20 hidden-nav-items', 
              { 'f-medium-orange': currentComponent.sectionId === item.sectionId }
            ]" 
            @click="changeSection(item)"
          >
            {{ item.name }}
          </button>
          <ul v-if="currentComponent.sectionId === item.sectionId">
            <li 
              v-for="(child, childIndex) in item.children" 
              :key="`child-${childIndex}`" 
              class="nav-child"
            >
              <button 
                :class="[
                  'child-nav-button f-medium-grey uppercase pointer',
                  { 'f-medium-orange': currentComponent.sectionId === item.sectionId && child.id === currentComponent.id }
                ]" 
                @click="changeSection(child)"
              >
                {{ child.name }}
              </button>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import { COMPONENTS, PRIMARY_ANIMATE_TIMELINE } from '@/config/constants';

export default {
  name: 'SideMenu',
  data() {
    return {
      subMenuIsOpen: false
    }
  },
  computed: {
    menu() {
      return this.$store.getters['MENU'];
    },
    currentComponent() {
      return this.$store.getters['CURRENT_COMPONENT'];
    }
  },
  methods: {
    changeSection(item) {
      if (item.component === COMPONENTS.SERVICES) {
        this.$store.commit('SET_SERVICE', item);
      } else {
        this.$store.commit('SET_CURRENT_COMPONENT', item);
      }

      this.$emit('resetScroll');
    },
    startComponentAnimation() {
      const firstStep = () => {
        [].slice.call(this.$el.querySelectorAll('.parent-nav-button')).forEach((element, index) => {
          setTimeout(() => {
            this.animate(element, { 'opacity': 1, 'margin-left': 0 }, 0.3);
          }, 100 * index);
        });
      };

      this.execute(firstStep, PRIMARY_ANIMATE_TIMELINE.SIDE_MENU);
    }
  },
  mounted() {
    this.startComponentAnimation();
  }
}
</script>