<template>
  <header 
    :class="[{ 'open' : menuOpen }]" 
    v-scroll="scrollHandler"
  >
    <button @click="$store.commit('SET_SLIDER')">
      <LogoIcon class="logo-icon" :color="COLORS.ORANGE" /> 
    </button>
    <button 
      class="menu-button" 
      @click="menuOpen = !menuOpen"
    >
      <MenuIcon :color="menuOpen ? COLORS.GREY : COLORS.ORANGE" />
    </button>
    <nav class="navigation" v-show="menuOpen">
      <ul class="nav-items">
        <li v-for="(item, index) in menu" :key="`item-${index}`" class="nav-item">
          <button 
            :class="[
              'nav-button uppercase f-sbold', 
              { 'active': currentComponent.sectionId === item.sectionId }
            ]" 
            @click="changeSection(item)"
          >
            {{ item.name }}
          </button>
          <ul>
            <li 
              v-for="(child, childIndex) in item.children" 
              :key="`child-${childIndex}`" 
            >
              <button 
                :class="[
                  'nav-child-button f-medium-grey uppercase',
                  { 'active': currentComponent.sectionId === item.sectionId && child.id === currentComponent.id }
                ]" 
                @click="changeSection(child)"
              >
                {{ child.name }}
              </button>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  </header>
</template>

<script>
import { MenuIcon, LogoIcon } from '@/svg';
import { COLORS, COMPONENTS } from '@/config/constants';

export default {
  name: 'MobileNav',
  components: {
    MenuIcon,
    LogoIcon
  },
  data() {
    return {
      menuOpen: false
    }
  },
  computed: {
    COLORS() {
      return COLORS;
    },
    menu() {
      return this.$store.getters['MENU'];
    },
    currentComponent() {
      return this.$store.getters['CURRENT_COMPONENT'];
    }
  },
  methods: {
    scrollHandler() {
      this.menuOpen = false;
    },
    changeSection(item) {
      if (item.component === COMPONENTS.SERVICES) {
        this.$store.commit('SET_SERVICE', item);
      } else {
        this.$store.commit('SET_CURRENT_COMPONENT', item);
      }

      window.scrollTo(0, 0);
      this.menuOpen = false;
    },
    outside(event) {
      if (!this.$el.contains(event.target) && this.menuOpen)
        this.menuOpen = false;
    }
  },
  mounted() {
    document.body.addEventListener('click', this.outside, false);
  },
  destroyed() {
    document.body.removeEventListener('click', this.outside, false);
  }
}
</script>