<template>
  <div 
    class="full-width content-box hidden"
    v-observe-visibility="scrollHandler"
  >
    <div class="full-width hidden">
      <h1 
        class="f-caption-grey content-box-title pb-30"
        v-show="showTitle"
        v-html="title"
      >
        {{ title }}
      </h1>
    </div>
    <div 
      class="full-width content-box-paragraphs"
      v-show="showContent && list.length"
    >
      <p 
        v-for="(item, itemIndex) in list"
        :key="`item-${itemIndex}`"
        class="f-medium-grey pb-15"
      >
        {{ item }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContentBox',
  props: {
    list: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    time: {
      type: [String, Number],
      required: true
    }
  },
  data() {
    return {                
      done: false,
      showTitle: false,
      showContent: false
    }
  },
  methods: {
    startComponentAnimation(visible) {
      if (visible && !this.done) {
        this.done = true;

        const firstStep = () => {
          this.animate(this.$el, { 'opacity': 1 }, 0.5);
          this.showTitle = true;
        };
        
        this.execute(firstStep, this.time);
        this.execute(() => { 
          this.showContent = true;
        }, this.time + 500);

        this.execute(() => {
          if (this.DESKTOP && this.$el) {
            this.$emit(
              'setTitleHeight', 
              Math.round(
                +getComputedStyle(this.$el.querySelector('.content-box-title')).height.slice(0, -2)
              )
            );
          }
        }, this.time + 1000);
      }
    },
    scrollHandler(visible, entry) {
      this.startComponentAnimation(visible);
    }
  },
  watch: {
    title: {
      immediate: true,
      handler(value) {
        this.done = false;
        this.showTitle = false;
        this.showContent = false;
        if (value) this.startComponentAnimation(true);
      }
    }
  }
}
</script>