<template>
  <div class="loader">
    <svg 
      class="loader-icon"
      style="margin: auto; display: block; shape-rendering: auto;" 
      width="200px" 
      height="200px" 
      viewBox="0 0 100 100" 
      preserveAspectRatio="xMidYMid"
    >
      <g transform="translate(50 50)">
        <g transform="rotate(6.92612)">
          <animateTransform 
            attributeName="transform" 
            type="rotate" 
            values="0;45" 
            keyTimes="0;1" 
            dur="0.24875621890547261s" 
            repeatCount="indefinite">
          </animateTransform>
          <path d="M30.508195620193604 -5.5 L41.508195620193604 -5.5 L41.508195620193604 5.5 L30.508195620193604 5.5 A31 31 0 0 1 25.46163930133064 17.683464708278613 L25.46163930133064 17.683464708278613 L33.239813894382664 25.461639301330635 L25.461639301330635 33.23981389438266 L17.683464708278613 25.461639301330635 A31 31 0 0 1 5.500000000000004 30.5081956201936 L5.500000000000004 30.5081956201936 L5.500000000000005 41.508195620193604 L-5.5 41.508195620193604 L-5.500000000000001 30.508195620193604 A31 31 0 0 1 -17.683464708278606 25.46163930133064 L-17.683464708278606 25.46163930133064 L-25.461639301330628 33.239813894382664 L-33.23981389438266 25.46163930133064 L-25.461639301330635 17.683464708278613 A31 31 0 0 1 -30.5081956201936 5.500000000000006 L-30.5081956201936 5.500000000000006 L-41.508195620193604 5.500000000000007 L-41.508195620193604 -5.499999999999998 L-30.508195620193604 -5.499999999999999 A31 31 0 0 1 -25.461639301330642 -17.683464708278606 L-25.461639301330642 -17.683464708278606 L-33.239813894382664 -25.461639301330628 L-25.46163930133064 -33.23981389438266 L-17.683464708278613 -25.461639301330635 A31 31 0 0 1 -5.500000000000008 -30.5081956201936 L-5.500000000000008 -30.5081956201936 L-5.50000000000001 -41.508195620193604 L5.499999999999995 -41.508195620193604 L5.4999999999999964 -30.508195620193604 A31 31 0 0 1 17.683464708278603 -25.461639301330642 L17.683464708278603 -25.461639301330642 L25.461639301330624 -33.239813894382664 L33.23981389438266 -25.461639301330642 L25.461639301330635 -17.683464708278617 A31 31 0 0 1 30.5081956201936 -5.500000000000011 M0 -22A22 22 0 1 0 0 22 A22 22 0 1 0 0 -22"></path>
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
  export default {
    name: 'Loader'
  }
</script>