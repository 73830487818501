<template>
  <div class="slider sections">
    <section class="section" id="slider-section">
      <div class="slide">
        <div class="slider-logo">
          <img 
            class="slide-saver"
            :src="require(`@/assets/images/slider/slide-logo.png`)" 
            alt="saver"
            v-if="showPhotoSaver"
          />
          <img 
            class="slide-photo"
            :src="slider[currentIndex].img" 
            alt="slide" 
            v-if="showSliderPhoto"
          />
        </div>
      </div>
    </section>
    <section class="section hidden" id="content-section">
      <div class="main-section-content">
        <div class="hidden full-width">
          <h1 class="f-caption-grey full-width slider-title" v-if="showTitle">
            {{ slider[currentIndex].title }}
          </h1>
        </div>
        <div class="hidden full-width">
          <p class="f-medium-grey full-width slider-description" v-if="showDescription">
            {{ slider[currentIndex].description }}
          </p>
        </div>
      </div>  
    </section>
  </div>
</template>

<script>
export default {
  name: 'Slider',
  data() {
    return {
      currentIndex: 0,
      showPhotoSaver: false,
      showSliderPhoto: false,
      showTitle: false,
      showDescription: false
    }
  },
  computed: {
    slider() {
      return this.$store.getters['SLIDER'];
    }
  },
  methods: {
    startComponentAnimation() {
      if (this.DESKTOP) {
        const firstSection = document.getElementById('slider-section');
        const secondSection = document.getElementById('content-section');
        
        this.animate(firstSection, { 'height': 0, 'min-height': 0 });
        this.animate(secondSection, { 'height': 0, 'min-height': 0 });

        const style = { 'height': '100vh', 'min-height': '100vh' };

        const firstStep = () => { this.animate(firstSection, style, 0.5) };
        this.execute(firstStep, 100);

        const secondStep = () => { this.animate(secondSection, style, 0.5) };
        this.execute(secondStep, 500);
      }

      this.execute(this.showContent, this.DESKTOP ? 700 : 0);
    },
    showContent() {
      const firstStep = () => { this.showPhotoSaver = true };
      this.execute(firstStep, 0);

      const secondStep = () => { this.runSlider(0) };
      this.execute(secondStep, 300);
    },
    runSlider(index) {
      const changeSlide = () => {
        this.currentIndex++;
        
        if (this.currentIndex == this.slider.length) {
            this.currentIndex = 0;
        }

        const firstStep = () => { this.showDescription = true };
        this.execute(firstStep, 100);

        const secondStep = () => {
            this.showTitle = true;
            this.showSliderPhoto = true;
        };
        this.execute(secondStep, 300);

        const thirdStep = () => {
            this.showSliderPhoto = false;
            this.showDescription = false;
            this.showTitle = false;
        };
        this.execute(thirdStep, 9900);
      };
      
      changeSlide();
      setInterval(() => { changeSlide() }, 10000);
    }
  },
  mounted() {
    this.startComponentAnimation();
  }
}
</script>