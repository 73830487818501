<template>
  <div class="team-member-info-mask">
    <div class="team-member-info">
      <div class="info-close">
        <button @click="$emit('close')" v-show="showButton" class="pointer">
          <CloseIcon />
        </button>
      </div>            
      <div 
        class="team-member-info-photo"
        :style="{ backgroundImage: `url(${info.info_image})` }"
        v-show="showPhoto"
      >
        <img 
          :src="require('@/assets/images/about_us/member-fond.png')" 
          class="team-member-photo-fond"
          alt="member"
        />
      </div>
      <div class="full-width hidden pb-10">
        <p 
          class="f-large-grey f-sbold member-info-title"
          v-show="showName"
        >
          {{ info.name }}
        </p>
      </div>
      <div class="full-width hidden pb-10">
        <p 
          class="f-small-grey member-info-description"
          v-show="showInfo"
        >
          {{ info.info }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { CloseIcon } from '@/svg';

export default {
  name: 'TeamMemberInfo',
  props: {
    info: {
      type: Object,
      required: true
    }
  },
  components: {
    CloseIcon
  },
  data() {
    return {
      showPhoto: false,
      showName: false,
      showInfo: false,
      showButton: false
    }
  },
  watch: {
    info: {
      immediate: true,
      handler (newVal, oldVal) {
        if (newVal != oldVal)
          this.startComponentAnimation();
        }
    }
  },
  methods: {
    startComponentAnimation() {
      this.showPhoto = false;
      this.showName = false;
      this.showInfo = false;
      this.showButton = false;

      const firstStep = () => {
        this.showPhoto = true;
      };

      const secondStep = () => {
        this.showName = true;
      };

      const thirdStep = () => {
        this.showInfo = true;
        this.showButton = true;
      };

      this.execute(firstStep, 50);
      this.execute(secondStep, 400);
      this.execute(thirdStep, 700);
    }
  },
  mounted() {
    this.$nextTick(() => { this.startComponentAnimation() });
  }
}
</script>