<template>
  <div class="animated-logo">
    <svg 
      viewBox="0 0 100 100"
      class="animated-logo-line"
      :height="lineBoxHeight"
    >
      <line 
        x1="50" 
        x2="50" 
        :y1="y1Position" 
        :y2="y2Position"
        stroke-width="2"
      />
    </svg>
    <svg 
      viewBox="0 0 843.325927734375 800.1339111328125" 
      x="0px" 
      y="0px"
      class="animated-logo-img"
    >
      <rect                 
        x="0" 
        y="0" 
        style="fill: white; fill-opacity: 0; pointer-events: none;"
      />
      <g transform="matrix(5.488043785095215, 0, 0, 5.488043785095215, -67.16517210006714, -358.7386474609375)">
        <g transform="matrix(2.028342644908692, 0, 0, 2.028342644908692, -18.858051652052076, -72.92889015507849)">
          <path 
            stroke-width="1"
            v-show="showLogo"
            class="oval"
            fill-rule="evenodd" 
            clip-rule="evenodd" 
            fill="none" 
            stroke-miterlimit="10" 
            d="M53.3,68.7L53.3,68.7c3.3,0,6.1,2.7,6.1,6.1v25.4c0,3.3-2.7,6.1-6.1,6.1h0c-3.3,0-6.1-2.7-6.1-6.1V74.8C47.2,71.4,49.9,68.7,53.3,68.7L53.3,68.7z"
          />
          <path 
            stroke-width="1"
            v-show="showLogo"
            class="horseshoe"
            fill-rule="evenodd"   
            clip-rule="evenodd" 
            fill="none" 
            stroke-miterlimit="10" 
            d="M32.3,71.3C22.5,78,16,89.3,16,102.1c0,20.6,16.7,37.2,37.2,37.2c20.6,0,37.2-16.7,37.2-37.2c0-12.8-6.4-24.1-16.3-30.8c0,0,0-0.1-0.1,0l0,0c-1-0.7-2.2-1.1-3.5-1.1c-3.4,0-6.2,2.8-6.2,6.2c0,2.3,1.2,4.3,3.1,5.3c6.4,4.5,10.6,12,10.6,20.4c0,13.7-11.1,24.9-24.9,24.9c-13.7,0-24.9-11.1-24.9-24.9c0-8.5,4.3-16,10.8-20.5c0.1,0,0.1-0.1,0.2-0.1c0.1-0.1,0.4-0.1,0.3-0.2l0,0c1.5-1.1,2.4-2.9,2.4-4.9c0-3.4-2.8-6.2-6.2-6.2C34.6,70.1,33.4,70.5,32.3,71.3L32.3,71.3C32.4,71.2,32.3,71.3,32.3,71.3L32.3,71.3z"
          />
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'AnimatedLogo',
  data() {
    return {
      lineBoxHeight: 0,
      y1Position: 0,
      y2Position: 0,
      showLogo: false
    }
  },
  methods: {
    startComponentAnimation() {
      const line = this.$el.querySelector('.animated-logo-line');
      const img = this.$el.querySelector('.animated-logo-img');
      
      this.lineBoxHeight = this.getCoords(img).top + (this.getWidth(img) / 100 * 1.4);

      const firstStep = () => {
        const intervalID = setInterval(() => {
          if (this.y2Position == 100) {
            clearInterval(intervalID);
            this.showLogo = true;
          } else {
            this.y2Position++;
          };
        }, 7);
      };

      const secondStep = () => {
        const intervalID = setInterval(() => {
          if (this.y1Position == 100) {
            clearInterval(intervalID);
          } else {
            this.y1Position++;
          }
        }, 7);
      };

      this.execute(firstStep, 0);
      this.execute(secondStep, 600);
    }
  },
  mounted() {
    this.startComponentAnimation();
  }
}
</script>