<template>
  <div class="screen-saver">
    <div class="screen-saver-section">
      <div class="grey-section"></div>
    </div>
    <div class="screen-saver-section">
      <div class="green-section"></div>
    </div>
    <div class="screen-saver-section">
      <div class="orange-section"></div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ScreenSaver',
    methods: {
      start() {
        this.animate(this.$el.querySelector('.grey-section'), { 'width': '2px' }, 1);
        this.animate(this.$el.querySelector('.green-section'), { 'width': '2px' }, 1);
        this.animate(this.$el.querySelector('.orange-section'), { 'width': '2px' }, 1);
      }
    },
    mounted() {
      this.execute(this.start, 1000);
    }
  }
</script>