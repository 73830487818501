<template>
  <div 
    class="team-member"
    v-observe-visibility="scrollHandler"
  >
    <div class="pb-10">
      <div 
        class="team-member-photo pointer"
        :style="{ backgroundImage: `url(${data.image})` }"
        @click="$emit('set', data)"
        v-if="showPhoto"
      >
        <img 
          :src="require('@/assets/images/about_us/member-fond.png')" 
          class="team-member-photo-fond"
          alt="member"
        />
      </div>
    </div>
    <div class="team-member-data">
      <div class="full-width hidden">
        <p class="member-title f-medium-grey f-sbold" v-if="showName">
          {{ data.name }}
        </p>
      </div>
      <div class="full-width hidden">
        <p class="member-position f-small-grey" v-if="showPosition">
          {{ data.position }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'TeamMember',
    props: {
      data: {
        type: Object,
        required: true
      },
      time: {
        type: [String, Number],
        required: true
      }
    },
    data() {
      return {
        done: false,
        showPhoto: false,
        showIcons: false,
        showName: false,
        showPosition: false
      }
    },
    methods: {
      scrollHandler(visible, entry) {
        if (visible && !this.done) {
          this.done = true;

          this.animate(this.$el, { 'opacity': 0 });

          const firstStep = () => {
            this.animate(this.$el, { 'opacity': 1 }, 0.5);
            this.showPhoto = true;
          };

          this.execute(firstStep, this.time);
          this.execute(() => { this.showName = true }, this.time + 600);
          this.execute(() => { this.showPosition = true }, this.time + 900);
        }
      }
    }
}
</script>