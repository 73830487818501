<template>
  <div class="sections">
    <section class="section main-top-padding">
      <div class="about-us-data">
        <ContentBox
          :title="data.title"
          :list="data.description"
          :time="100"
        />
      </div>
    </section>
    <section class="section content-top-padding">
      <div class="about-us-exposition">
        <Exposition 
          :title="data.exposition.title"
          :list="data.exposition.list"
          :time="DESKTOP ? 1300 : 700"
        />
      </div>
    </section>
  </div>
</template>

<script>
import { ContentBox, Exposition } from '@/components';

export default {
  name: 'WhyUs',
  components: {
    ContentBox,
    Exposition 
  },
  computed: {
    data() {
      return this.$store.getters['WHY_US'];
    }
  }
}
</script>