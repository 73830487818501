import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

import { SCREEN_SIZES, COMPONENTS, SECTION_ID } from '@/config/constants';

const SLIDER = {
  name: 'Slider',
  sectionId: SECTION_ID.SLIDER,
  component: COMPONENTS.SLIDER
};

const NEWS = {
  name: 'News',
  sectionId: SECTION_ID.NEWS,
  component: COMPONENTS.NEWS,
  children: []
};

const POST = {
  name: 'Post',
  sectionId: SECTION_ID.NEWS,
  component: COMPONENTS.POST
};

const MENU = [
  {
    name: 'About us',
    sectionId: SECTION_ID.ABOUT_US,
    component: COMPONENTS.ABOUT_US,
    children: [
      // {
      //   name: 'Our team',
      //   id: 0,
      //   sectionId: SECTION_ID.ABOUT_US,
      //   component: COMPONENTS.OUR_TEAM,  
      // },
      {
        name: 'Why us?',
        id: 1,
        sectionId: SECTION_ID.ABOUT_US,
        component: COMPONENTS.WHY_US
      }
    ]
  },
  {
    name: 'Services',
    sectionId: SECTION_ID.SERVICES,
    component: COMPONENTS.SERVICES,
    children: []
  },
  {
    name: 'Contact us',
    sectionId: SECTION_ID.CONTACT_US,
    component: COMPONENTS.CONTACT_US,
    children: []
  },
  // NEWS
];

export default new Vuex.Store({
  state: {
    screenSize: 0,
    data: null,
    post: null,
    service: null,
    menu: MENU,
    currentComponent: SLIDER
  },
  getters: {
    SCREEN_SIZE: state => state.screenSize,
    MENU: state => state.menu,
    CURRENT_COMPONENT: state => state.currentComponent,

    SLIDER: state => state.data.slider,
    NEWS_LIST: state => state.data.news,
    POST: state => state.post,
    ABOUT_US: state => state.data.aboutus.aboutus,
    OUR_TEAM: state => state.data.aboutus.team,
    WHY_US: state => state.data.aboutus.whyus,
    SERVICE: state => state.service,
    CONTACTS: state => state.data.contacts
  },
  mutations: {
    SET_SCREEN_SIZE(state, payload) {
      if (payload <= 767) {
        state.screenSize = SCREEN_SIZES.MOBILE
      } else if (payload >= 767 && payload <= 1366) {
        state.screenSize = SCREEN_SIZES.TABLET
      } else {
        state.screenSize = SCREEN_SIZES.DESKTOP
      } 
    },
    SET_DATA(state, payload) {
      state.data = payload;

      if (!payload.services.length) return;

      state.menu[1].children = payload.services.map((service, index) => {
        return { 
          name: service.title || '',
          id: index,
          component: COMPONENTS.SERVICES,
          sectionId: SECTION_ID.SERVICES
        }
      });
    },
    SET_SLIDER(state) {
      state.currentComponent = SLIDER;
    },
    SET_ALL_NEWS(state){
      state.currentComponent = NEWS;
    },
    SET_POST(state, payload) {
      state.post = state.data.posts.find(p => p.id === payload);
      state.currentComponent = POST;
    },
    SET_CURRENT_COMPONENT(state, payload) {
      state.currentComponent = payload; 
    },
    SET_SERVICE(state, payload) {
      const service = payload.id !== undefined
        ? state.data.services[payload.id] 
        : state.data.service_page;

      state.service = service;
      state.currentComponent = payload;
    }
  },
  actions: {
    GET_DATA({ commit }) {
      return new Promise((resolve, reject) => {
        fetch(`${process.env.VUE_APP_API_URL}/wp-json/translete/v2/data`)
          .then(response => response.json())
          .then(result => {
            commit('SET_DATA', result);
            resolve(result);
          })
          .catch((error) => { reject(error) });
      });
    },
    SEND_EMAIL({ commit }, params) {
      return new Promise((resolve, reject) => {
        fetch(`${process.env.VUE_APP_API_URL}/wp-json/translete/v2/send-email`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(params)
        })
        .then((response) => { resolve(response) })
        .catch((error) => { reject(error) });
      });
    }
  }
});
