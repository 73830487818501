<template>
  <footer>
    <button 
      class="basement-logo" 
      @click="$store.commit('SET_SLIDER')"
    >
      <LogoTextIcon :color="COLORS.WHITE"/>
      <LogoIcon :color="COLORS.WHITE"/>
    </button>
    <div class="basement-contacts">
      <a 
        v-for="(email, index) in contacts.emails"
        :key="`basement-email-${index}`"
        :href="`mailto:${email}`"
      >
        {{ email }}
      </a>
      <span class="basement-address">
        {{ contacts.address }}
      </span>
      <div class="copyright">
        <p>Copyright © {{ year }} Translate-ON</p>
        <p>All rights reserved</p>
      </div>
    </div>
  </footer>
</template>

<script>
import { LogoIcon, LogoTextIcon } from '@/svg';
import { COLORS } from '@/config/constants';

export default {
  name: 'Basement',
  components: {
    LogoIcon,
    LogoTextIcon
  },
  computed: {
    COLORS() {
      return COLORS;
    },
    contacts() {
      return this.$store.getters['CONTACTS'];
    },
    year() {
      return new Date().getFullYear();
    }
  }
}
</script>