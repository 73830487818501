<template>
  <div class="full-width about-us">
    <div class="sections">
      <section class="section main-top-padding">
        <div class="about-us-data">
          <ContentBox
            :title="data.title"
            :list="data.description"
            :time="100"
          />
        </div>
      </section>
      <section 
        class="about-us-logo-section" 
        :style="{ backgroundImage: `url(${data.img})` }"
      >
        <AnimatedLogo />
      </section>
    </div>
  </div>
</template>

<script>
import { ContentBox, AnimatedLogo } from '@/components';

export default {
  name: 'AboutUs',
  components: {
    ContentBox,
    AnimatedLogo
  },
  computed: {
    data() {
      return this.$store.getters['ABOUT_US'];
    }
  }
}
</script>