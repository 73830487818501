<template>
  <svg 
    viewBox="0 0 541.8259887695312 107.65445709228516" 
    x="0px"
    y="0px"
    class="logo-text-icon"
    :fill="color"
  >
    <rect id="ee-background" x="0" y="0" width="541.8259887695312" height="107.65445709228516" style="fill: white; fill-opacity: 0; pointer-events: none;"/>
    <g transform="matrix(3.6208314895629883, 0, 0, 3.6208314895629883, -61.41059136390686, -121.2215347290039)">
      <path d="M 17.600000381469727 34.29999923706055 L 37.099998474121094 34.29999923706055 L 37.099998474121094 36.599998474121094 L 28.700000762939453 36.599998474121094 L 28.700000762939453 62.5 L 26.100000381469727 62.5 L 26.100000381469727 36.599998474121094 L 17.600000381469727 36.599998474121094 L 17.600000381469727 34.29999923706055 Z"/>
      <path d="M42.4,62.5h-2.5V45.6l-0.2-3.8h2.4v4.4c0.3-1.3,0.9-2.4,1.7-3.3c0.9-1,2.1-1.5,3.4-1.5c0.6,0,1.4,0.1,2.3,0.4v2.4c-0.3-0.1-0.6-0.2-1-0.3c-0.4-0.1-0.8-0.1-1-0.1c-1.5,0-2.6,0.6-3.6,1.9c-0.9,1.3-1.4,2.9-1.4,4.9V62.5L42.4,62.5z"/>
      <path d="M53.4,45.2v-2.4c0.8-0.4,1.8-0.7,2.8-0.9c1.1-0.2,2.2-0.4,3.3-0.4c3.1,0,5.2,1,6.3,2.9c0.7,1.2,1,2.7,1,4.8V58l0.1,4.5h-2.3V59c-0.8,1.5-2,2.5-3.4,3.1c-1,0.5-2.2,0.7-3.4,0.7c-2.2,0-4-0.6-5.2-1.9c-1.1-1.1-1.6-2.4-1.6-4c0-2.2,0.8-3.9,2.3-5c1.9-1.4,5-2.1,9.5-2.1h1.3v-0.7c0-1.5-0.2-2.7-0.7-3.5c-0.8-1.3-2.1-2-4.1-2C57.4,43.7,55.3,44.2,53.4,45.2L53.4,45.2z M64.4,52.1h-2.3c-2.5,0-4.4,0.3-5.7,0.9c-1.7,0.8-2.5,2.1-2.5,3.9c0,1.2,0.4,2.1,1.2,2.8c0.8,0.7,1.8,1.1,2.9,1.1c2.1,0,3.7-0.7,4.8-2c1.1-1.3,1.7-3.1,1.7-5.2V52.1L64.4,52.1z"/>
      <path d="M74.9,62.5h-2.5V46.6l-0.1-4.8h2.3v4.3c0.6-1.3,1.4-2.4,2.3-3.1c1.2-1,2.7-1.5,4.5-1.5c3,0,5.1,1,6.2,3.1c0.7,1.2,1,2.9,1,5v12.9H86V49.8c0-1.9-0.2-3.1-0.5-3.9c-0.7-1.5-2.2-2.3-4.6-2.3c-1.8,0-3.3,0.7-4.4,2.1c-1.1,1.4-1.7,3-1.7,4.7V62.5L74.9,62.5z"/>
      <path d="M104.7,42.6l-0.2,2c-1.6-0.6-3.2-1-4.9-1c-1.4,0-2.4,0.3-3.2,0.8c-0.8,0.5-1.1,1.2-1.1,2.1c0,1,0.3,1.8,1,2.3c0.5,0.4,1.6,1,3.3,1.7c1.7,0.7,2.9,1.3,3.7,1.9c1.4,1.2,2.1,2.6,2.1,4.4c0,1.7-0.6,3.1-1.8,4.2c-1.3,1.2-3.2,1.7-5.6,1.7c-1.9,0-3.7-0.3-5.4-0.9l0.2-2.4c0.8,0.4,1.7,0.7,2.6,0.9c0.9,0.2,1.8,0.3,2.8,0.3c1.5,0,2.7-0.3,3.5-1c0.8-0.7,1.2-1.6,1.2-2.7c0-1.2-0.4-2.1-1.2-2.8c-0.4-0.3-1.1-0.7-2.2-1.2c-2.2-0.9-3.7-1.7-4.5-2.3c-1.3-1-2-2.4-2-4.1c0-1.5,0.6-2.7,1.9-3.6c1.2-0.9,2.9-1.4,5.1-1.4c0.9,0,1.8,0.1,2.5,0.3C103,42,103.8,42.2,104.7,42.6L104.7,42.6z"/>
      <path d="M 109.30000305175781 31.799999237060547 L 111.9000015258789 31.799999237060547 L 111.9000015258789 62.5 L 109.30000305175781 62.5 L 109.30000305175781 31.799999237060547 Z"/>
      <path d="M117.9,45.2v-2.4c0.8-0.4,1.8-0.7,2.8-0.9c1.1-0.2,2.2-0.4,3.3-0.4c3.1,0,5.2,1,6.3,2.9c0.7,1.2,1,2.7,1,4.8V58l0.1,4.5h-2.3V59c-0.8,1.5-2,2.5-3.4,3.1c-1,0.5-2.2,0.7-3.4,0.7c-2.2,0-4-0.6-5.2-1.9c-1.1-1.1-1.6-2.4-1.6-4c0-2.2,0.8-3.9,2.3-5c1.9-1.4,5-2.1,9.5-2.1h1.3v-0.7c0-1.5-0.2-2.7-0.7-3.5c-0.8-1.3-2.1-2-4.1-2C121.8,43.7,119.8,44.2,117.9,45.2L117.9,45.2z M128.8,52.1h-2.3c-2.5,0-4.4,0.3-5.7,0.9c-1.7,0.8-2.5,2.1-2.5,3.9c0,1.2,0.4,2.1,1.2,2.8c0.8,0.7,1.8,1.1,2.9,1.1c2.1,0,3.7-0.7,4.8-2c1.1-1.3,1.7-3.1,1.7-5.2V52.1L128.8,52.1z"/>
      <path d="M138.5,36.7l2.5-0.9v6.1h4.9V44H141v12.7c0,1.7,0.2,2.8,0.6,3.4c0.3,0.4,0.9,0.6,1.8,0.6c0.5,0,1,0,1.3-0.1c0.4-0.1,0.8-0.2,1.2-0.4v2.1c-0.5,0.2-1,0.3-1.4,0.3c-0.5,0.1-0.9,0.1-1.5,0.1c-2,0-3.4-0.8-4.1-2.3c-0.4-0.9-0.6-2.1-0.6-3.7V44h-4.2v-2.1h4.2V36.7L138.5,36.7z"/>
      <path d="M165.4,52.9h-14.6c0,2.2,0.6,4,1.9,5.5c1.4,1.6,3.2,2.5,5.5,2.5c1.7,0,3.5-0.5,5.6-1.5v2.3c-1.9,0.8-3.8,1.2-5.7,1.2c-2.9,0-5.2-0.8-6.9-2.5c-2-1.9-3-4.8-3-8.5c0-3,0.8-5.5,2.4-7.4c1.6-1.9,3.8-2.9,6.6-2.9c3.5,0,5.8,1.4,7.1,4.2c0.8,1.6,1.1,3.7,1.1,6.4V52.9L165.4,52.9z M150.8,50.8h11.9c0-1.8-0.3-3.3-0.9-4.4c-0.9-1.8-2.5-2.7-4.7-2.7c-2,0-3.7,0.8-4.8,2.4C151.3,47.5,150.8,49,150.8,50.8L150.8,50.8z"/>
    </g>
  </svg>
</template>

<script>
  export default {
    nane: 'LogoTextIcon',
    props: {
      color: {
        type: String,
        default: ''
      }
    }
  }
</script>