<template>
  <button 
    class="logo pointer" 
    v-if="showLogo" 
    @click="$store.commit('SET_SLIDER')"
  >
    <LogoTextIcon class="logo-text-icon" :color="COLORS.GREEN" />
    <LogoIcon class="logo-icon" :color="COLORS.ORANGE" />     
  </button>
</template>

<script>
import { LogoIcon, LogoTextIcon } from '@/svg';
import { COLORS, PRIMARY_ANIMATE_TIMELINE } from '@/config/constants';

export default {
  name: 'Logo',
  components: {
    LogoIcon,
    LogoTextIcon
  },
  data() {
    return {
      showLogo: false
    }
  },
  computed: {
    COLORS() {
      return COLORS;
    }
  },
  mounted() {
    this.execute(() => { this.showLogo = true }, PRIMARY_ANIMATE_TIMELINE.LOGO);
  }
}
</script>