<template>
  <div class="sections">
    <section class="section main-top-padding">
      <div class="full-width hidden pb-30">
        <ContentBox
          :title="data.title"
          :list="[]"
          :time="100"
        />
      </div>
      <TeamMember
        v-for="(member, index) in data.members"
        :key="`member-${index}`"
        :data="member"
        :time="startTime(index)"
        @set="setMemberInfo"
      />
    </section>
    <section class="section">
      <div class="about-us-member-info">
        <keep-alive>
          <TeamMemberInfo
            v-if="showMemberInfo"
            :info="selectedMember"
            @close="showMemberInfo = false"
          />
        </keep-alive>
      </div>
    </section>
  </div>
</template>

<script>
import { ContentBox, TeamMember, TeamMemberInfo } from '@/components';

export default {
  name: 'OurTeam',
  components: {
    ContentBox,
    TeamMember,
    TeamMemberInfo
  },
  data() {
    return {
      showMemberInfo: false,
      selectedMember: null
    }
  },
  computed: {
    data() {
      return this.$store.getters['OUR_TEAM'];
    }
  },
  methods: {
    startTime(index) {
      const wh = window.innerHeight;

      if (index === 1) {
        return wh > 736 ? 1300 : 900;
      } else if (index === 2) {
        return wh > 736 ? 1600 : 1100;
      } else {
        return 700;
      }
    },
    setMemberInfo(data) {
      this.selectedMember = data;
      this.showMemberInfo = true;
    }
  }
}
</script>