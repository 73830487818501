import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import PerfectScrollbar from 'vue2-perfect-scrollbar';
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css';

import VueObserveVisibility from 'vue-observe-visibility';

import style from './assets/style/app.scss';
import directives from './directives';
import filters from './filters';
import units from './mixins/units';

Vue.mixin(units);
Vue.use(VueObserveVisibility);
Vue.use(PerfectScrollbar);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
