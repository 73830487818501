<template>
  <video 
    autoplay="true" 
    muted="muted" 
    preload="auto"
  >
    <!-- <source :src="mp4" type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'> -->
    <source :src="webm" type='video/webm; codecs="vp8, vorbis"'>
  </video>
</template>

<script>
export default {
  name: 'CubesMovie',
  data() {
    return {
      // mp4: require('@/assets/video/cubes.mp4'),
      webm: require('@/assets/video/cubes.webm')
    }
  }
}
</script>