<template>
  <div class="sections">
    <section class="section">
      <div class="contact-us">
        <div class="full-width hidden">
          <h2 class="contact-us-title f-caption-grey">Contact Us</h2>
        </div>
        <div class="contact-us-subtitle-wrap full-width hidden">
          <span 
            class="contact-us-subtitle f-medium-grey" 
            v-if="showSubTitle"
          >
            Please fill in all required fields (*)
          </span>
        </div>
        <form @submit.prevent="tryToSubmitTheForm">
          <div class="form-fields" v-if="showFormFields">
            <div class="form-field">
              <div class="full-width hidden">
                <span class="form-fields-title f-medium-grey">Your Name*</span>
              </div>
              <input 
                type="text" 
                id="name" 
                v-model="data.name" 
                @focus="removeErrorStyle('name')"
              />
            </div>
            <div class="form-field">
              <div class="full-width hidden">
                <span class="form-fields-title f-medium-grey">Your Email*</span>
              </div>
              <input 
                type="email" 
                id="email" 
                v-model="data.email" 
                @focus="removeErrorStyle('email')"
              />
            </div>
            <div class="form-field">
              <div class="full-width hidden">
                <span class="form-fields-title f-medium-grey">Subject*</span>
              </div>
              <input 
                type="text" 
                id="subject" 
                v-model="data.subject" 
                @focus="removeErrorStyle('subject')"
              />
            </div>
            <div class="form-field">
              <div class="full-width hidden">
                <span class="form-fields-title f-medium-grey">Message*</span>
              </div>
              <textarea 
                id="message" 
                v-model="data.message" 
                @focus="removeErrorStyle('message')"
              />
            </div>
          </div>
          <div>
            <VueRecaptcha
              ref="recaptcha"
              size="invisible"
              :sitekey="siteKey"
              :loadRecaptchaScript="true"
              @verify="validateBeforeSubmit"
              @expired="onCaptchaExpired"
            />
          </div>
          <div class="form-submit">            
            <SendButton
              v-if="showFormButton"
              :sending="sending"
              :success="success"
              :error="sendingError"
            />
          </div>
        </form>
      </div>
    </section>
    <section 
      class="contacts-section" 
      v-if="DESKTOP"
    >
      <div class="contacts-section-saver hidden">
        <div class="contacts-section-content" v-if="showContacts">
          <div 
            class="contacts-section-emails"
            v-for="(email, index) in contacts.emails"
            :key="`email-${index}`"
          >
            <a :href="`mailto:${email}`" target="_blank">
              {{ email }}
            </a>
          </div>
          <div class="contacts-section-address" v-if="showEmails">
            <span class="">{{ contacts.address }}</span>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { EMAIL_REGEXP } from '@/config/constants';
import { SendButton } from '@/components';
import VueRecaptcha from 'vue-recaptcha'

export default {
  name: 'ContactUs',
  components: {
    SendButton,
    VueRecaptcha
  },
  data() {
    return {
      data: {
        name: '',
        email: '',
        subject: '',
        message: ''
      },
      errors: [],
      sending: false,
      success: false,
      sendingError: false,
      showSubTitle: false,
      showFormFields: false,                
      showFormButton: false,
      showContacts: false,
      showEmails: false,
      siteKey: process.env.VUE_APP_RECAPTCHA_KEY
    }
  },
  computed: {
    contacts() {
      return this.$store.getters['CONTACTS'];
    }
  },
  methods: {
    startComponentAnimation() {
      const firstStep = () => { this.showSubTitle = true };
      this.execute(firstStep, 400);

      const secondStep = () => { this.showFormFields = true };
      this.execute(secondStep, 700);

      const thirdStep = () => { this.showFormButton = true };
      this.execute(thirdStep, 1200);

      if (this.DESKTOP) {
        const box = this.$el.querySelector('.contacts-section-saver');
        this.animate(box, { 'width': 0 });

        const fourthStep = () => { this.animate(box, { 'width': '100%' }, 0.7) }
        this.execute(fourthStep, 800);

        const fifthStep = () => { this.showContacts = true };
        this.execute(fifthStep, 1800);

        const sixthStep = () => { this.showEmails = true };
        this.execute(sixthStep, 2200);
      }
    },
    tryToSubmitTheForm() {
      this.$refs.recaptcha.execute();
    },
    validateBeforeSubmit(recaptchaToken) {
      if (!recaptchaToken) return;
      
      this.errors.splice(0, this.errors.length);

      for (let field in this.data) {
        if (this.data[field] == '') {
          this.errors.push(field);
        };
      };

      if (!EMAIL_REGEXP.test(this.data.email)) {
        this.errors.push('email');
      };

      this.errors.length ? this.showErrors() : this.send(recaptchaToken);
    },
    showErrors() {
      this.errors.map((error) => {
        document.getElementById(error).classList.add('error');
      });
    },
    removeErrorStyle(id) {
      document.getElementById(id).classList.remove('error');
    },
    send(recaptchaToken) {
      if (this.errors.length || !recaptchaToken) return;

      this.sending = true;

      this.$store.dispatch('SEND_EMAIL', this.data)
        .then((response) => {          
          if (response.status === 200) {
            this.success = true;
  
            for (let field in this.data) {
              this.data[field] = '';
            };
          } else {
            this.sendingError = true;
          }
        })
        .catch((error) => {
          this.sendingError = true;
        })
        .finally(() => {
          this.sending = false;
          this.$nextTick(() => {
            this.success = false;
            this.sendingError = false;
          });
        });
    },
    onCaptchaExpired() {
      this.$refs.recaptcha.reset();
    }
  },
  mounted() {
    this.startComponentAnimation();
    
    const $script = document.createElement('script');
    $script.async = true;
    $script.src =
      'https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit';
    document.head.appendChild($script);
  }
}
</script>