export const LOADING_STATUS = {
  LOADING: 1,
  PENDING: 2,
  LOADED: 3
}

export const SCREEN_SIZES = {
  MOBILE: 'mobile', // <= 767
  TABLET: 'tablet', // > 768 && <= 1366
  DESKTOP: 'desktop' // > 1367
};

export const EMAIL_REGEXP = /^[-._a-z0-9]+@(?:[a-z0-9][-a-z0-9]+\.)+[a-z]{2,6}$/;

export const COLORS = {
  GREY: '#a2a2a2',
  ORANGE: '#ff7f28',
  WHITE: '#ffffff',
  GREEN: '#32a847'
};

export const MOVIE = {
  TEXT: 'Text',
  MESSAGE: 'Message',
  CLEF: 'Clef',
  CUBES: 'Cubes'
};

export const COMPONENTS = {
  SLIDER: 'Slider',
  NEWS: 'News',
  POST: 'Post',
  ABOUT_US: 'AboutUs',
  OUR_TEAM: 'OurTeam',
  WHY_US: 'WhyUs',
  SERVICES: 'Services',
  CONTACT_US: 'ContactUs'
};

export const PRIMARY_ANIMATE_TIMELINE = {
  LOGO: 2000,
  CONTENT: 2000,
  SIDE_MENU: 2500
};

export const SECTION_ID = {
  SLIDER: 0,
  ABOUT_US: 1,
  SERVICES: 2,
  CONTACT_US: 3,
  NEWS: 4
};
