<template>
  <svg 
    viewBox="0 0 245.79541015625 117.8895263671875" 
    x="0px"
    y="0px"
    class="logo-icon"
    :fill="color"
  >
    <rect id="ee-background" x="0" y="0" width="245.79541015625" height="117.8895263671875" style="fill: white; fill-opacity: 0; pointer-events: none;"/>
    <g transform="matrix(1.6178220510482788, 0, 0, 1.6178220510482788, -24.40678608417511, -109.21536254882812)">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M32.3,71.3C22.5,78,16,89.3,16,102.1c0,20.6,16.7,37.2,37.2,37.2c20.6,0,37.2-16.7,37.2-37.2c0-12.8-6.4-24.1-16.3-30.8c0,0,0-0.1-0.1,0l0,0c-1-0.7-2.2-1.1-3.5-1.1c-3.4,0-6.2,2.8-6.2,6.2c0,2.3,1.2,4.3,3.1,5.3c6.4,4.5,10.6,11.9,10.6,20.4c0,13.7-11.1,24.9-24.9,24.9c-13.7,0-24.9-11.1-24.9-24.9c0-8.5,4.3-16,10.8-20.5c0.1,0,0.1-0.1,0.2-0.1c0.1-0.1,0.4-0.1,0.3-0.2l0,0c1.5-1.1,2.4-2.9,2.4-4.9c0-3.4-2.8-6.2-6.2-6.2C34.6,70.1,33.4,70.5,32.3,71.3L32.3,71.3C32.4,71.2,32.3,71.3,32.3,71.3L32.3,71.3z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M162.9,70.6c-1.5-1.5-3.7-2.5-6-2.5c-2.3,0-4.5,0.9-6,2.5c-1.5,1.5-2.5,3.7-2.5,6h0l0,0.2v0l0,31l0,8.6l-4.9-7.1l-24.6-36l0,0c-1.1-1.6-2.5-2.9-4.2-3.8c-1.6-0.9-3.5-1.4-5.5-1.4h0v0c-3.2,0-6.1,1.3-8.2,3.4c-2.1,2.1-3.4,5-3.4,8.3h0c0.1,11,0,22,0,33c0,6,0,12.1,0,18.1h0c0,2.3,0.9,4.4,2.5,6c1.5,1.5,3.7,2.5,6,2.5c2.3,0,4.4-0.9,6-2.5c1.5-1.5,2.5-3.6,2.5-6V99.9v-8.7l4.9,7.2l23.5,34.3l0.1,0.1l0.1,0.1c1,1.9,2.6,3.5,4.4,4.6c1.8,1.1,3.9,1.7,6.1,1.7c3.3,0,6.2-1.3,8.4-3.4c2.2-2.1,3.5-5,3.6-8.3l0-51.1h0C165.4,74.2,164.5,72.1,162.9,70.6L162.9,70.6z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M53.3,68.7L53.3,68.7c3.3,0,6.1,2.7,6.1,6.1v25.4c0,3.3-2.7,6.1-6.1,6.1h0c-3.3,0-6.1-2.7-6.1-6.1V74.8C47.2,71.4,49.9,68.7,53.3,68.7L53.3,68.7z"/>
    </g>
  </svg>
</template>

<script>
  export default {
    name: 'LogoIcon',
    props: {
      color: {
        type: String,
        default: ''
      }
    }
  }
</script>