<template>
  <div 
    class="full-width list-items"
    v-observe-visibility="scrollHandler"
  >
    <h3 class="full-width f-medium-grey pb-25 list-title">{{ title }}</h3>
    <ul>
      <li 
        v-for="(listItem, listItemIndex) in list"
        :key="`list-item-${listItemIndex}`"
        class="f-medium-grey pb-5"
      >
        {{ listItem }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'List',
  props: {
    list: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    time: {
      type: [String, Number],
      required: true
    }
  },
  data() {
    return {                
      done: false
    }
  },
  methods: {
    startComponentAnimation(visible) {
      if (visible && !this.done) {
        this.done = true;

        const title = this.$el.querySelector('.list-title');
        this.animate(this.$el, { 'opacity': 0 });
        this.animate(title, { 'margin-top': `${500}px` });

        const firstStep = () => {
          this.animate(this.$el, { 'opacity': 1 }, 0.5);
          this.animate(title, { 'margin-top': 0 }, 0.6);
        };
        
        this.execute(firstStep, this.time);              
      }
    },
    scrollHandler(visible, entry) {
      this.startComponentAnimation(visible);
    }
  },
  watch: {
    list: {
      immediate: true,
      deep: true,
      handler(value, oldValue) {
        this.done = false;
        if (value && oldValue !== undefined) this.startComponentAnimation(true);
      }
    }
  }
}
</script>
