<template>
  <button class="send-button" type="submit">
    <SendLoader v-if="sending"/>
    <span v-else>Send</span>
    <span 
      class="send-button-notification letter-success"
      v-if="letterIsSent"
    >
      Letter is sent successfully!
    </span>
    <span 
      class="send-button-notification letter-error"
      v-if="letterIsNotSent"
    >
      Something went <br /> wrong :(
    </span>
  </button>
</template>

<script>
import { SendLoader } from '@/svg';

export default {
  name: 'SendButton',
  props: {
    sending: {
      type: Boolean,
      default: false
    },
    success: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    }
  },
  components: {
    SendLoader
  },
  data() {
    return {
      letterIsSent: false,
      letterIsNotSent: false
    }
  },
  watch: {
    success: {
      immediate: true,
      handler (newVal, oldVal) {
        if (newVal) {
          this.letterIsSent = true;
          this.execute(() => { this.letterIsSent = false }, 5000);
        }
      }
    },
    error: {
      immediate: true,
      handler (newVal, oldVal) {
        if (newVal) {
          this.letterIsNotSent = true;
          this.execute(() => { this.letterIsNotSent = false }, 5000);
        }
      }
    }
  }
}
</script>