<template>
  <div class="sections">
    <section class="section main-top-padding">
      <div class="news">
        <transition-group name="fade-news-list" v-if="receivedNewsLength">
          <div 
            class="news-list-item"
            v-for="(item, index) in newsList" 
            :key="`news-list-${index}`"
          >
            <button class="news-list-button pointer" @click="setPost(item.id)">
              <span class="f-medium-grey">{{ item.created_at | date }}</span>
              <h3 class="f-caption-grey">{{ item.title }}</h3>
            </button>
          </div>
        </transition-group>
        <div v-else>
          <span class="f-medium-grey f-sbold">There's no news yet.</span>
        </div>
      </div>
    </section>
    <section class="section" v-if="DESKTOP">
      <div class="news-video" v-if="receivedNewsLength">
        <VideoBox :name="MOVIE.TEXT" :time="1000" />
      </div>
    </section>
  </div>
</template>

<script>
import { VideoBox } from '@/components';

export default {
  name: 'News',
    components: {
      VideoBox
    },
    data() {
      return {
        newsList: []
      }
    },
    computed: {
      receivedNews() {
        return this.$store.getters['NEWS_LIST'];
      },
      receivedNewsLength() {
        return this.receivedNews.length;
      }
    },
    methods: {
      startComponentAnimation() {
        this.pushItems(this.receivedNews, this.newsList, 200);
      },
      setPost(postId) {
        this.$store.commit('SET_POST', postId);
        window.scrollTo(0, 0);
      }
    },
    mounted() {
      this.execute(this.startComponentAnimation, 300);
    }
}
</script>