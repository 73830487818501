<template>
  <div class="sections">
    <section class="section main-top-padding">
      <div class="news">
        <h3 class="f-caption-grey">{{ post.title }}</h3>
        <div class="news-img" v-if="post.img.length">
          <img :src="post.img" alt="post" />
        </div>
        <p class="f-medium-grey word-break">{{ post.body }}</p>
        <div class="to-all-news">
          <button class="pointer" @click="toAllNewsPage">
            <ArrowIcon />
            <span class="f-medium-grey uppercase">to all news</span>
          </button>
        </div>
      </div>
    </section>
    <section class="section" v-if="DESKTOP">
      <div class="news-video">
        <VideoBox :name="MOVIE.TEXT" :time="500" />
      </div>
    </section>
  </div>
</template>

<script>
import { ArrowIcon } from '@/svg';
import { VideoBox } from '@/components';

export default {
  name: 'Post',
  components: {
    ArrowIcon,
    VideoBox
  },
  computed: {
    post() {
      return this.$store.getters['POST'];
    }
  },
  methods: {
    toAllNewsPage() {
      this.$store.commit('SET_ALL_NEWS');
      window.scrollTo(0, 0);
    }
  }
}
</script>