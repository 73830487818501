<template>
  <div 
    class="full-width exposition-list"
    v-observe-visibility="scrollHandler"
  >
    <h3 class="f-medium-grey pb-25 exposition-list-title">{{ title }}</h3>
    <div 
      v-for="(item, itemIndex) in list"
      :key="`exposition-list-item-${itemIndex}`"
      :class="['f-medium-grey exposition-list-item', `exposition-list-item-${itemIndex}`]"
    >
      {{ item }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'Exposition',
  props: {
    list: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    time: {
      type: [String, Number],
      required: true
    }
  },
  data() {
    return {                
      done: false
    }
  },
  methods: {
    startComponentAnimation(visible) {
      if (visible && !this.done) {
        this.done = true;

        const title = this.$el.querySelector('.exposition-list-title');
        const list = this.$el.querySelectorAll('.exposition-list-item');
        
        this.animate(title, { 'margin-top': `${300}px` });

        const firstStep = () => {
          this.animate(this.$el, { 'opacity': 1 }, 0.5);
          this.animate(title, { 'margin-top': 0 }, 0.5);
        };

        const secondStep = () => {
          [].slice.call(list).forEach((el, index) => {
            setTimeout(() => {
              el.classList.add('exposition-list-item-animate');
            }, 200 * index);
          });
        };
        
        this.execute(firstStep, this.time);
        this.execute(secondStep, this.time + 200);
      }
    },
    scrollHandler(visible, entry) {
      this.startComponentAnimation(visible);
    }
  },
  watch: {
    list: {
      immediate: true,
      deep: true,
      handler(value, oldValue) {
        this.done = false;
        if (value && oldValue !== undefined) this.startComponentAnimation(true);
      }
    }
  }
}
</script>