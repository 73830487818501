<template>
  <div class="video-box" v-observe-visibility="scrollHandler">
    <component :is="`${name}Movie`" v-if="show" />
  </div>
</template>

<script>
import { TextMovie, MessageMovie, ClefMovie, CubesMovie } from '@/movie';
import { MOVIE } from '@/config/constants';

export default {
  name: 'VideoBox',
  props: {
    name: {
      type: String,
      default: MOVIE.TEXT
    },
    time: {
      type: [String, Number],
      required: true
    }
  },
  components: {
    TextMovie, 
    MessageMovie, 
    ClefMovie, 
    CubesMovie 
  },
  data() {
    return {
      show: false,
      done: false,
    }
  },
  methods: {
    startComponentAnimation(visible) {
      if (visible && !this.done) {
        this.done = true;
        this.execute(() => { this.show = true }, this.time);
      }
    },
    scrollHandler(visible, entry) {
      this.startComponentAnimation(visible);
    }
  },
  watch: {
    name: {
      immediate: true,
      handler(value) {
        this.done = false;
        this.show = false;
        if (value) this.startComponentAnimation(true);
      }
    }
  }
}
</script>