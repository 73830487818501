<template>
  <div id="app">
    <Loader v-if="loadingStatus === LOADING_STATUS.LOADING" />
    <ScreenSaver v-else-if="loadingStatus === LOADING_STATUS.PENDING" />
    <div v-else-if="loadingStatus === LOADING_STATUS.LOADED">
      <Logo v-if="DESKTOP"/>
      <MobileNav v-else />   
      <router-view />
    </div>
  </div>
</template>

<script>
import { Loader, ScreenSaver, Logo, MobileNav } from '@/components';
import { LOADING_STATUS, PRIMARY_ANIMATE_TIMELINE } from '@/config/constants';

export default {
  name: 'App',
  components: {
    Loader,
    ScreenSaver,
    Logo,
    MobileNav
  },
  data() {
    return {
      loadingStatus: LOADING_STATUS.LOADING
    }
  },
  computed: {
    LOADING_STATUS() {
      return LOADING_STATUS;
    }
  },
  methods: {
    checkScreenSize() {
      this.$store.commit('SET_SCREEN_SIZE', window.innerWidth);
    }
  },
  created() {
    this.$store.dispatch('GET_DATA')
      .finally(() => {
        this.loadingStatus = this.LOADING_STATUS.PENDING;
        this.execute(() => { 
          this.loadingStatus = this.LOADING_STATUS.LOADED 
        }, PRIMARY_ANIMATE_TIMELINE.CONTENT);
      });
  },
  mounted() {            
    this.checkScreenSize();
    window.addEventListener('resize', this.checkScreenSize);
  },
  destroyed() {
    window.removeEventListener('resize', this.checkScreenSize);
  }
}
</script>
